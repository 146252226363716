<script>
  import { mapActions } from "vuex";
  import "@vueform/multiselect/themes/default.css";
  import {
    CountTo
  } from "vue3-count-to";
  import {
    required,
    helpers
  } from "@vuelidate/validators";
  import useVuelidate from "@vuelidate/core";


  import appConfig from "../../../../app.config";
  import Swal from "sweetalert2";
  import axios from 'axios';
  import animationData from "@/components/widgets/msoeawqm.json";
  import animationData1 from "@/components/widgets/gsqxdxog.json";
  //import Lottie from "@/components/widgets/lottie.vue";
  import moment from 'moment'
  import serverConfig from '@/helpers/config';
  
  
  export default {
    name:'QTableProjects',
    setup() {
      moment.locale('it')
    
    
      let tk = 'Bearer '+localStorage.getItem('tk')


      return { tk, v$: useVuelidate()};
    },
    page: {
      title: "Lista cantieri",
      meta: [{
        name: "description",
        content: appConfig.description
      }],
    },
    data() {
      return {
        UrlServer: serverConfig.EP,
        datastore: this.$store.state,
        title: "Lista cantieri",
        dataSumm:{},
        items: [{
            text: "Cantieri",
            href: "/quality-control/list-view",
          },
          {
            text: "Lista",
            active: true,
          },
        ],
        date3: null,
        rangeDateconfig: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
          mode: "range",
        },
        config: {
          wrap: true, // set wrap to true only when using 'input-group'
          altFormat: "M j, Y",
          altInput: true,
          dateFormat: "d M, Y",
        },
        timeConfig: {
          enableTime: false,
          dateFormat: "d M, Y",
        },
        filterdate: null,
        filterdate1: null,
        filtervalue: 'All',
        filtervalue1: 'All',
        filtersearchQuery1:null,
        date: null,
        submitted: false,
        regError: null,
        tryingToRegister: false,
        isRegisterError: false,
        registerSuccess: false,
        item:{
          source:'',
          customerCategory:'',
          systemCategory:'',
          systemSubCategory:'',
          fiscalBenefit:'',
        },
        optionsFiscalBenefit:[],
        optionsFSPrivate:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'conto_termico', label: 'Conto termico' },
          { value: 'detrazione_fiscale', label: 'Detrazione fiscale' },
        ],
        optionsFSBusiness:[
          { value: '', label: 'Seleziona beneficio fiscale' },
          { value: 'credito_imposta', label: "Credito d'imposta" },
          { value: 'sconto_in_fattura', label: 'Sconto in fattura' },
        ],
        optionsFSPA:[
          { value: 'non_previsto', label: 'Non previsto' },
        ],
        allTask: [
        ],
        searchQuery: null,
        page: 1,
        perPage: 20,
        sortRes:'asc',
        value: 'All',
        pages: [],
        projectsList: [
        ],
        filter:false,
        paginated:{},
        select:'all',
        search:'',
        defaultOptions: {
          animationData: animationData
        },
        defaultOptions1: {
          animationData: animationData1
        },
        form:{
          start:'',
          end:'',
          status:'init',
          select:'',
        },
        n_results:0,
        minDate: moment().format('YYYY-MM-DD HH:MM'),
        maxDate: moment().format('YYYY')+ '-12-31 HH:MM',
        minDateEnd:moment().format('YYYY-MM-DD HH:MM'),
        maxDateEnd: moment().format('YYYY') + '-12-31 HH:MM',
      };
    },
    components: {
      CountTo,
    },
    validations: {
      item: {
        source: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        customerCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        systemSubCategory: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
        fiscalBenefit: {
          required: helpers.withMessage("Campo obbligatorio", required),
        },
      },
    },
    computed: {
      
    },
    watch: {
      allTask() {
        this.setPages();
      },
    },
    created() {
      this.setPages();
    },
    filters: {
      trimWords(value) {
        return value.split(" ").splice(0, 20).join(" ") + "...";
      },
    },
    beforeMount() {
      //this.getList()
    },

    methods: {
      convertDate(date){
        if (date) {
          moment.locale('it')
          return moment.unix(date).format('DD/MM/YYYY')
        }
      },
      updateDate(type){
        if (type=='start'){
          this.minDateEnd = this.form.start
          this.form.end = this.form.start
        }
        if (type=='end'){
          this.minDateEnd = this.form.start
        }
      },
      ...mapActions('layout', ['changeLayoutType', ]),
    
        axiosInterceptor(){
        axios.interceptors.response.use(
          (response) => {
            return response;
          },
          (error) => {
              if (error.response && error.response.status === 401) {
                Swal.fire({
                  title:"Accesso non autorizzato",
                  text:"Mi spiace, ma l'accesso a questa risorsa non è autorizzato, probabilmente a causa della sessione scaduta o per mancanza dei requisiti",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })

                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }
                  return Promise.reject('Unauthorized access');
              } else if (error.response && error.response.status  === 404) {
                  Swal.fire({
                    title:"Nessun risultato trovato",
                    text:"Mi spiace, nessun risultato trovato",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('The server cannot find the requested resource');
              }  else if (error.response && error.response.status  === 412) {
                Swal.fire({
                  title:"Credenziali mancanti",
                  text:"Inserisci delle credenziali valide, per favore.",
                  icon:"warning",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                return Promise.reject('The server cannot find the requested resource');
            } else if (error.response && error.response.status  === 423) {
                  Swal.fire({
                    title:"Risorsa non accessibile",
                    text:"Il contenuto al quale stai tentando di accedere non è accessibile.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  this.$router.push('/login')
                  if(localStorage.getItem('tk')){
                    localStorage.removeItem('tk')
                  }            
                  return Promise.reject('The server cannot find the requested resource');
              }
              else if (error.response && error.response.status  === 500) {
                Swal.fire({
                  title:"Errore Server",
                  text:"Mi spiace, ma riscontriamo degli errori sul server.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Internal Server Error');
              }
              else if (error.response && error.response.status  === 502) {
                  Swal.fire({
                    title:"Errore Server",
                    text:"Mi spiace, ma riscontriamo degli errori sul server.",
                    icon:"error",
                    showCancelButton:false,
                    showConfirmButton:false,
                    timer:2000,
                  })
                  return Promise.reject('Bad Gateway');
              }
              else if (error.response && error.response.status  === 503) {
                Swal.fire({
                  title:"Servizio non disponibile",
                  text:"Mi spiace, il servizio richiesto è momentaneamente indisponibile.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                  return Promise.reject('Service Unavailable');
              } else {
                console.log(error.response.status )
                Swal.fire({
                  title:"Errore imprevisto",
                  text:"Ops! si è verificato un problema...riprova, per favore.",
                  icon:"error",
                  showCancelButton:false,
                  showConfirmButton:false,
                  timer:2000,
                })
                
              }

          }
        );
        
      },
      getSummary() {
        let year = new Date().getFullYear()
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}projects/summary/${year.toString()}`, {
            headers: { 'authorization':this.tk
          }}).then((response) => {
            if (response.data) {
              this.dataSumm = response.data
            }        
          })
      },
      getListInPending(){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}quality/projects/in-pending`, 
          { 
            params: { 
              perPage: this.perPage,
              page: this.page,
              sortRes: this.sortRes,
              select: this.select,
              search: this.search,
            }, 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          this.projectsList = response.data.results.data;
          this.paginated = response.data.results
          this.n_results = response.data.n_results
        })
      },
      SearchData(){
        //if (this.form.start!='' && this.form.end!=''){
          this.axiosInterceptor()
          axios.get(`${this.UrlServer}quality/filter/all`, 
            { 
              params: { 
                perPage: this.perPage,
                page: this.page,
                sortRes: this.sortRes,
                filter:this.filter,
                select: this.select,
                search: this.search,
                start:moment(this.form.start).unix(),
                end:moment(this.form.end).unix(),
                status:this.form.status,
              }, 
              headers: { 'authorization':this.tk}
            }
          ).then(response=>{
            this.projectsList = response.data.results.data;
            this.paginated = response.data.results
            this.n_results = response.data.n_results
            this.createQueryString()
          })
       // }
      },
      resetQuery(){
        this.projectsList = []
        this.n_results = 0
        if (this.search) {
          if (this.search.length>5) {
            this.SearchData()
          }
        }
      },
      filterOptions(val){
        switch(val){
          case "azienda":
            this.getSettingsOptions("tax-benefit-business")
          break;
          case "privato":
            this.getSettingsOptions("tax-benefit-private")
          break;
          case "pubblico":
            this.getSettingsOptions("tax-benefit-pa")
          break;
          default:
          this.optionsFiscalBenefit = []
        }   
      },
      getSettingsOptions(tag){
        this.axiosInterceptor()
        axios.get(`${this.UrlServer}settings/all/documents/tag/${tag}`, 
          { 
            headers: { 'authorization':this.tk}
          }
        ).then(response=>{
          if (response.data) {
            this.optionsFiscalBenefit = []
            for (let item of response.data) {
              this.optionsFiscalBenefit.push({
                value:item.value,
                label: item.name
              })
            }
            
          }
        })
      },
      editdata(data) {
       
        this.$router.push('/quality-control/detail/'+data.projectID+'/'+data._id)
      },

      addnew() {
        document.getElementById("addform").reset();
        document.getElementById('createModalLabel').innerHTML = this.$t('t-new-project');
        document.getElementById('add-btn').style.display = 'block';
       
      },
      initItem(data){
        Swal.fire(
            {
              title: this.$t("t-createQP-confirm-setting-title"),
              text: this.$t("t-createQP-confirm-setting-text"),
              icon: this.$t("t-createQP-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-createQP-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-createQP-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-createQP-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-createQP-confirm-setting-confirmButtonText")
            }
          ).then((result) => {
            if (result.value) {
              try {
               // Create
               this.submitted = true;
                
                this.v$.$touch();

                this.item.name = 'Nuovo Piano della Qualità'
                this.item.dateString = moment().format('YYYY-MM-DD')
                this.item.startStr= moment().format('YYYY-MM-DD')
                this.item.month = moment().format('YYYY')
                this.item.day = moment().day()
                this.item.month = moment().month()
                this.item.year = parseInt(moment().format('YYYY'))
                this.item.start = moment().valueOf()
                this.item.start_tmp = moment().unix()
                this.item.createdBy = this.datastore.auth.currentUser
                this.item.projectID = data._id

                  axios.post(`${this.UrlServer}quality/register`, this.item, {headers: { authorization:this.tk}} )
                    .then((response)=>{
                      if (response.data.status=='OK' ){
                        let self = this

                        Swal.fire({  
                          title: this.$t("t-msg-quality-init") , 
                          text: this.$t("t-msg-quality-init-content"), 
                          icon:"success",              
                          showCancelButton:false,
                          showConfirmButton:false,
                          timer:3000
                          }).then(()=>{

                            if (response.data) {
                              if (response.data.status=='OK') {
                                self.$router.push('/quality-control/detail/'+data._id+'/'+response.data.result._id)
                              }
                            }
                            
                          });
                      }

                      
                    })
               // /Create
              }catch(error){
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

            }
          });
       
      },
      
      deletedata(id) {
          Swal.fire(
            {
              title: this.$t("t-delete-confirm-setting-title"),
              text: this.$t("t-delete-confirm-setting-text"),
              icon: this.$t("t-delete-confirm-setting-icon"),
              showCancelButton: true,
              cancelButtonText: this.$t("t-delete-confirm-cancel-button-text"),
              cancelButtonColor: this.$t("t-delete-confirm-setting-cancelButtonColor"),
              confirmButtonColor: this.$t("t-delete-confirm-setting-confirmButtonColor"),
              confirmButtonText: this.$t("t-delete-confirm-setting-confirmButtonText")
            }
          ).then((result) => {
            if (result.value) {
              try {
                this.axiosInterceptor()
                axios.delete(`${this.UrlServer}projects/${id}`, {headers: { authorization:this.tk}})
                  .then((response) => {
                    if (response.data) {
                      //this.getList()
                      Swal.fire(this.$t("t-msg-deleted") , this.$t("t-msg-data-deleted"), "success");
                    }

                  })
              }catch(error){
                Swal.fire( this.$t("t-msg-error") , this.$t("t-msg-error-content"), "error");
              }

            }
          });
      },  
      setPages() {
        let numberOfPages = Math.ceil(this.allTask.length / this.perPage);
        this.pages = [];
        for (let index = 1; index <= numberOfPages; index++) {
          this.pages.push(index);
        }
      },
      paginate(customerList) {
        let page = this.page;
        let perPage = this.perPage;
        let from = page * perPage - perPage;
        let to = page * perPage;
        return customerList.slice(from, to);
      },
      paginateAct(pg){
        this.page = pg
        //this.getList()
        this.SearchData()
      },
      splitStr(str,car){
        if (str){
          return str.replaceAll(car,' ')
        } else {
          return ''
        } 
      },
      createQueryString(){
        this.$router.push(this.$route.path+`?perPage=${this.perPage}&page=${this.page}&search=${this.search}&start=${moment(this.form.start).unix()}&end=${moment(this.form.end).unix()}&status=${this.form.status}`)
      },
    },
    mounted() {
      this.getSummary()
      this.getListInPending()
    }
  };
</script>

<template>
  <!-- SOMMARIO -->
  <div class="row">
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Commesse {{ new Date().getFullYear() }}</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.total">
                  <count-to :startVal="0" :endVal="dataSumm.total" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info-2 rounded-circle fs-4">
                    <i class="ri-ticket-2-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <template v-if="dataSumm.qualityPending>0">
                    <p class="fw-medium text-muted mb-0">
                      <a href="javascript:void(0)" @click="getListInPending" class="color-red">
                      Attesa Avvio Qualità
                      </a>
                    </p>
                  </template>
                  <template v-else>
                    <p class="fw-medium text-muted mb-0">
                      Attesa Avvio Qualità
                    </p>
                  </template>
                <h2 class="mt-4 ff-warning fw-semibold" v-if="dataSumm.qualityPending>0">
                  <a href="javascript:void(0)" @click="getListInPending" class="color-red">
                    <count-to :startVal="0" :endVal="dataSumm.qualityPending" :duration="5000"></count-to>
                  </a>
                </h2>
                <h2 class="mt-4 ff-warning fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <template v-if="dataSumm.qualityPending>0">
                      <a href="javascript:void(0)" @click="getListInPending">
                        <i class="mdi mdi-24px mdi-calendar-check-outline color-red" ></i>
                      </a>
                    </template>
                    <template v-else>
                      <i class="mdi mdi-24px mdi-calendar-check-outline"></i>
                    </template>

                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Commesse in lavorazione</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.processing">
                  <count-to :startVal="0" :endVal="dataSumm.processing" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-info rounded-circle fs-4">
                    <i class="mdi mdi-timer-sand"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Commesse in attesa</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.pending">
                  <count-to :startVal="0" :endVal="dataSumm.pending" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-warning rounded-circle fs-4">
                    <i class="bx bx-time-five "></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Commesse con problemi</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.pending">
                  <count-to :startVal="0" :endVal="dataSumm.problem" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-danger rounded-circle fs-4">
                    <i class="bx bx-alarm-exclamation"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-12">
        <div class="card card-animate">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div>
                <p class="fw-medium text-muted mb-0">Commesse chiuse</p>
                <h2 class="mt-4 ff-secondary fw-semibold" v-if="dataSumm.completed">
                  <count-to :startVal="0" :endVal="dataSumm.completed" :duration="5000"></count-to>
                </h2>
                <h2 class="mt-4 ff-secondary fw-semibold" v-else>
                  0
                </h2>
              </div>
              <div>
                <div class="avatar-sm flex-shrink-0">
                  <span class="avatar-title bg-success rounded-circle fs-4">
                    <i class="ri-checkbox-circle-line"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
   <!-- /Sommario -->
<!-- Modulo tabella elenco progetti/cantieri -->    
    <div class="row">
      <div class="col-lg-12">
        <div class="card" id="tasksList">
          <div class="card-header border-0">
            <div class="d-flex align-items-center">
              <h5 class="card-title mb-0 flex-grow-1">Ricerca tra tutte le commesse</h5>
              <div class="flex-shrink-0">
                
              </div>
            </div>
          </div>
      
          <div class="card-body border border-dashed border-end-0 border-start-0">
            <div class="mb-5">
              <div class="row rowSearchFormBGce g-4 mb-5">
                
                  <div class="col-lg-3 col-md-12">
                    <div class="">
                      <div class="search-box ms-2">
                        <input type="text" class="form-control"  :placeholder="$t('t-search-for') "  v-model="search" @input="resetQuery()" />
                        <i class="ri-search-line search-icon"></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-1 col-md-12">
                   
                      <div class="form-check mb-3">
                      <input class="form-check-input" type="checkbox" id="formCheckFilter" v-model="filter">
                        <label class="form-check-label" for="formCheckFilter">
                        Estendi filtro
                        </label>
                  
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12" v-if="filter">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-from')"   class="form-control" v-model="form.start" :min="minDate" :max="maxDate" @change="updateDate('start')">
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12" v-if="filter">
                    <div class="">
                      <input type="date"  :placeholder="$t('t-to')"   class="form-control" v-model="form.end" :min="minDateEnd" :max="maxDateEnd"  @change="updateDate('end')" >
                    </div>
                  </div>
                  <div class="col-lg-2 col-md-12" v-if="filter">
                    <select class="form-select" v-model="form.status">
                       <option value="all">Tutti</option>
                       <option value="init">Non assegnati</option>
                       <option value="assigned">Assegnati</option>
                       <option value="pending">Lavorazione in corso</option>
                       <option value="blocked">Bloccati</option>
                       <option value="canceled">Annullati</option>
                       <option value="completed">Completati</option>
                    </select>

                  </div>
                  <div class="col-lg-1 col-md-12">
                    <button type="button" class="btn btn-primary w-100" @click="SearchData">
                      <i class="ri-equalizer-fill me-1 align-bottom"></i>
                      {{ $t('t-filters') }}
                    </button>
                  </div>
                
              </div>
            </div>
            <div class="p-2">
            </div>
           <div class="table-card mt-2">
            <div class="row">
              <div class="col-lg-4 col-md-12 pl-20">
                <h5 class="card-title mb-0 flex-grow-1">
                  Risultati: <span v-if="n_results>0" class="text-green bold">{{ n_results }}</span>  <span v-if="n_results==0" class="text-red bold">{{ n_results }}</span>
                </h5>
              </div>
              <div class="col-lg-8 col-md-12">
              </div>
            </div>
                <div class="row thRow mt-50">
                    <div class="col-lg-2 col-md-12">
                        Cliente
                    </div>
                  
                    <div class="col-lg-2 col-md-12 text-left">
                        Elenco Documenti
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Pianificazione Risorse
                    </div>
                    
                    <div class="col-lg-2 col-md-12">
                      Cronoprogramma
                    </div>
                    <div class="col-lg-2 col-md-12">
                      Piano Controlli
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                        Azioni
                    </div>
                </div>
              
              <div class="row list tRow form-check-all d-flex" v-for="(item, index) of projectsList" :key="index">
                    <div class="col-lg-2 col-md-12 ">
                        <div class="list-inline hstack gap-2 mb-0" v-if="item.customerDetails">
                        
                         <strong>{{ item.customerDetails.ragSoc }}</strong>
                        
                        </div>
                        <div class="list-inline hstack gap-2 mb-0" v-else>
                          ND
                        </div>
                        <br>
                        <template v-if="item.worksite">
                        <template v-if="item.worksite.length>0">
                          {{ item.worksite[0].address }}
                          <br>
                          {{ item.worksite[0].city }} {{ item.worksite[0].zipCode }} 
                        </template>
                      </template>
                      <br>
                      {{ splitStr(item.systemCategory,'_') }}<br>
                      {{ splitStr(item.systemSubCategory,'_') }}<br>
                    </div>


                    <div class="col-lg-2 col-md-12 text-left">
                      <ul class="list-inline">
                          <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Gestione Documentale">
                            <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/quality-control/detail/dms/'+item._id)">
                              <i class="mdi mdi-24px mdi-archive-arrow-up-outline"></i>
                            </a>
                          </li>
                      </ul>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      <ul class="list-inline">
                         <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Pianifica risorse">
                           <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/quality-control/detail/plan/'+item._id)">
                             <i class="mdi mdi-24px mdi-calculator-variant-outline"></i>
                           </a>
                         </li>
                      </ul>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      <ul class="list-inline">
                         <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Cronoprogramma lavori">
                           <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/quality-control/detail/dms/'+item._id)">
                             <i class="mdi mdi-24px mdi-calendar-month color-orangeAccent"></i>
                           </a>
                         </li>
                      </ul>
                    </div>
                    <div class="col-lg-2 col-md-12 text-left">
                      
                      <ul class="list-inline">
                         <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Piano Controlli">
                           <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="$router.push('/quality-control/detail/plan/'+item._id)">
                             <i class="mdi mdi-24px mdi-calendar-check-outline color-purpleAccent"></i>
                           </a>
                         </li>
                      </ul>
                    </div>
                    <div class="col-lg-1 col-md-12 text-left">
                        <template v-if="item.quality">
                          <template v-if="item.quality.length>0">
                            <ul class="list-inline mt-5">
                              <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Modifica Piano di Qualità">
                                <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="editdata(item.quality[0])">
                                  <i class="mdi mdi-clipboard-edit-outline mdi-24px color-greenAccent"></i>
                                </a>
                              </li>
                            </ul>
                          </template>
                          <template v-else>
                            <ul class="list-inline">
                              <li class="list-inline-item edit" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="top" title="Avvia Piano di Qualità">
                                <a href="javascript:void(0)" class="text-primary d-inline-block edit-item-btn" @click="initItem(item)">
                                  <i class="mdi mdi-note-plus-outline mdi-24px color-red"></i>
                                </a>
                              </li>
                            </ul>
                          </template>
                        </template>

                    </div>
                </div>
                <div class="d-flex tRow justify-content-end mt-3 mb-5" v-if="n_results > perPage">
                    <div class="col-lg-2 col-md-12 text-right">
                        <label class="pt-2">Risultati per pagina:</label>
                    </div>        
                    <div class="col-lg-1 col-md-12 ">
                        <div class="pl-10 pr-20">
                            <select class="form-select" v-model="perPage" @change="paginateAct(paginated.pre_page)">
                                <option v-for="item in [5,10,20,50,100]" :key="item" :value="item">{{ item}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 ">
                        <div class="pagination-wrap hstack gap-2">
                        <a class="page-item pagination-prev disabled" href="#" v-if="paginated.pre_page > 1" @click="paginateAct(paginated.pre_page)">
                            Precedenti
                        </a>
                        <ul class="pagination listjs-pagination mb-0" >
                            <template v-for="index in paginated.total_pages" :key="index">
                                <li :class="{
                                    active: index == page,
                                    disabled: index == '...',
                                    }"  v-if="index<5">
                                <a class="page" href="#" @click="paginateAct(index)">{{ index}}</a>
                            </li>
                            </template>

                        
                        </ul>
                       
                        <template v-if="page>=5">
                            ...
                            <ul class="pagination listjs-pagination mb-0" >
                                <li class="active"  >
                                <a class="page" href="javascript:void(0)">{{ page}}</a>
                            </li>
                            
                        </ul>
                        </template>
                        <a class="page-item pagination-next" href="#" @click="paginateAct(paginated.next_page)" >
                        Successivi
                        </a>
                        </div>
                    </div>
              </div>
            </div>
           
           <!-- END TABLE -->
        
          </div>
          <!--end card-body-->
        </div>
        <!--end card-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->

<!-- / Modulo tabella elenco progetti/cantieri -->      
</template>